import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useRouter } from 'next/router';
import Link from 'next/link';
import getDefaultTab from '../helpers/getDefaultTab';
import isMobile from '../helpers/isMobile';

import DangerousHTML from './DangerousHTML';
import * as WEBSITES from '../constants/website';
import { PRIMARY } from '../constants/bannerTypes';

// import appConfig from '../appConfig';
import useSegment from '../hooks/segment';
import cannacraftBanner from '../constants/cannacraftBanner';
import lowellBanner from '../constants/lowellBanner';
import { SCHEDULE, ASAP } from '../constants/deliveryType';
import { useDeliveryDetails, useZipCodeStatus } from '../hooks/app';
import { getInImgFormate } from '../helpers/imageOptimization';
import appContext from '../Context/appContext';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import { useCommonFunctions } from '../hooks/common';
import { isNewHomePageApplicable } from '../constants/feature';
import { useSelector } from 'react-redux';
import { useConfigData } from '../Context/ConfigProvider';

const rcSettings = {
  showArrows: false,
  autoPlay: true,
  showIndicators: false,
  showThumbs: false,
  infiniteLoop: true,
  interval: 5000,
  transitionTime: 800,
  showStatus: false,
  useKeyboardArrows: false,
  swipeable: false,
  emulateTouch: false
};


const Banner = ({
  children,
  className,
  customBanner: receivedCustomBannerData = {},
  loading = false,
  onlyHomePage = false,
  bannerData: receivedBannerData,
  initialLocationAddress,
  initialDeliveryAddressDetails
}) => {
  // const {
  //   state: {
  //     user: { isLoggedIn }
  //   }
  // } = useContext(appContext);

  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const appConfig = useConfigData();
  const router = useRouter();
  const {
    data: {
      asapEnabled = true,
      customText,
      freightLimit,
      unified_menu,
      customTextSchedule,
      customTextAsap,
      customTextScheduleTimeSpecificText,
      customTextAsapTimeSpecificText,
      lastSlotCutOff,
      asapEndTime,
      asapStartTime
    } = {}
  } = useDeliveryDetails(initialDeliveryAddressDetails);
  const {
    query: { tab }
  } = router;
  let defaultBannerArray = [];
  const { redirectToLink } = useCommonFunctions();
  switch (process.env.NEXT_PUBLIC_APP) {
    case 'cannacraft':
      defaultBannerArray = cannacraftBanner;
      break;
    case 'lowell':
      defaultBannerArray = lowellBanner;
      break;
    default:
      break;
  }

  const {
    data: { locationId }
  } = useZipCodeStatus(initialLocationAddress);

  let currentTab = tab || getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled });

  if (!appConfig.isGrassdoor && unified_menu == 1) {
    currentTab = SCHEDULE;
  }

  if (!asapEnabled) {
    currentTab = SCHEDULE;
  }

  const asapBanner =
    receivedBannerData &&
      receivedBannerData[currentTab] &&
      receivedBannerData[currentTab].primary &&
      receivedBannerData[currentTab].primary.length
      ? receivedBannerData[currentTab]
      : {};

  const customBanner =
    receivedCustomBannerData &&
      receivedCustomBannerData[currentTab] &&
      receivedCustomBannerData[currentTab].primary &&
      receivedCustomBannerData[currentTab].primary.length &&
      receivedCustomBannerData[currentTab].primary[0].desktop_image_url
      ? receivedCustomBannerData[currentTab]
      : {};
  const bannerData = customBanner.primary || asapBanner.primary || defaultBannerArray;
  const secondaryBannerData = customBanner.secondary || asapBanner.secondary || [];
  const hideBanner = false;

  const hasMoreThanOneBanner = bannerData && bannerData.length > 1;
  const [segmentFlag, setSegmentFlag] = useState(true);
  const [initialFlag, setInitialFlag] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const { trackEvent } = useSegment();

  useEffect(() => {
    setSelectedItem(0);
  }, [bannerData, isLoggedIn]);

  useEffect(() => {
    const firstRefine = async () => {
      if (bannerData && bannerData.length) {
        trackEvent('Promotion Viewed', {
          promotion_id: '1',
          creative: bannerData[0].mobile_image_url || bannerData[0].desktop_image_url,
          name: bannerData[0].header,
          alt: bannerData[0].header,
          position: 'home_banner_top'
        }, true);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function slideChange(index) {
    if (index === selectedItem) return;
    setSelectedItem(index);
    if (segmentFlag) {
      trackEvent('Promotion Viewed', {
        promotion_id: `${index + 1}`,
        creative: isMobile ? bannerData[index]?.mobile_image_url : bannerData[index]?.desktop_image_url,
        name: bannerData[index]?.header,
        alt: bannerData[index]?.header,
        position: 'home_banner_top'
      }, true);
      setInitialFlag(index);
      if (bannerData && bannerData.length == index + 1) {
        setSegmentFlag(false);
      }
    }
  }

  if (!bannerData) {
    return <div>Banner loading</div>;
  }

  function renderShippingCharges() {
    let shippingChargesText = '';
    if (currentTab == SCHEDULE) {
      if (customTextScheduleTimeSpecificText && customTextScheduleTimeSpecificText !== '') {
        shippingChargesText = customTextScheduleTimeSpecificText;
      } else if (customTextSchedule && customTextSchedule !== '') {
        shippingChargesText = customTextSchedule;
      } else {
        return null;
      }
    } else if (currentTab == ASAP) {
      if (customTextAsapTimeSpecificText && customTextAsapTimeSpecificText !== '') {
        shippingChargesText = customTextAsapTimeSpecificText;
      } else if (customTextAsap && customTextAsap !== '') {
        shippingChargesText = customTextAsap;
      } else {
        return null;
      }
    }
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <i className="icon-driver-car d-none d-lg-block text-primary" />
        <div className="">
          <span className="">{shippingChargesText}</span>
        </div>
      </div>
    );
  }

  if (process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR) {
    return (
      <div
        className={`banner relative ${className} banner-control-${bannerData.length} ${isNewHomePageApplicable ? 'slider-wrap' : 'marketing-banner'
          } default-site-banner full-width-banner `}
      >
        {!loading && bannerData && bannerData.length > 0 && (
          <div className="top-banner" test-id="#top_banner_container">
            <Carousel
              {...rcSettings}
              showIndicators={hasMoreThanOneBanner}
              swipeable={hasMoreThanOneBanner}
              onChange={slideChange}
              className=""
              selectedItem={selectedItem}
            >
              {bannerData.map((bannerItem, i) => (
                <div id={bannerItem.id} key={bannerItem.id} className="banner-inner ">
                  <BannerImage bannerItem={bannerItem} secondaryBannerData={secondaryBannerData} />
                  <div className="container-fluid  just-a-container ">
                    <div className="banner-content">
                      {bannerItem.header && (
                        <h1 className="">
                          <DangerousHTML html={bannerItem.header} className="mb-0" />
                        </h1>
                      )}
                      {bannerItem.sub_header ? (
                        <h2 className="">
                          <DangerousHTML html={bannerItem.sub_header} className="mb-0" />{' '}
                        </h2>
                      ) : null}

                      {bannerItem.paragraph && (
                        <div className=" half-rem-mt  b-description white-color">
                          <DangerousHTML html={bannerItem.paragraph} className="mb-0" />
                        </div>
                      )}
                      {bannerItem.button_text && (
                        <a onClick={() => redirectToLink(bannerItem, i, PRIMARY)} className="click-capture-tag" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        {/* {secondaryBannerData && secondaryBannerData[0] && (
          <div className="side-promo-container s-banner relative">
            <picture className="w-100">
              <source srcSet={getInImgFormate(secondaryBannerData[0].mobile_image_url, 'webp').desktop} type="image/webp" />
              <source srcSet={getInImgFormate(secondaryBannerData[0].mobile_image_url, 'jpeg').desktop} type="image/jpeg" />
              <img alt="banner" src={getInImgFormate(secondaryBannerData[0].mobile_image_url).secondary} />
            </picture>
            {secondaryBannerData[0].button_link && <a className="click-capture-tag" href={secondaryBannerData[0].button_link} />}
          </div>
        )}
        {secondaryBannerData && secondaryBannerData[1] && (
          <div className="side-promo-container t-banner relative">
            <picture className="w-100">
              <source srcSet={getInImgFormate(secondaryBannerData[1].mobile_image_url, 'webp').secondary} type="image/webp" />
              <source srcSet={getInImgFormate(secondaryBannerData[1].mobile_image_url, 'jpeg').desktop} type="image/jpeg" />
              <img alt="secondary-banner" src={getInImgFormate(secondaryBannerData[1].mobile_image_url).secondary} />
            </picture>
            {secondaryBannerData[1].button_link && <a className="click-capture-tag" href={secondaryBannerData[1].button_link} />}
          </div>
        )} */}
      </div>
    );
  }

  if (process.env.NEXT_PUBLIC_APP == WEBSITES.GOVANA || process.env.NEXT_PUBLIC_GRASSDOOR_SITES) {
    return (
      <div
        className={`banner relative ${className} px-0 default-site-banner with-secondary-banner mt-3 ${!secondaryBannerData.length ? 'no-secondary-banner' : 'with-secondary-banner'
          }`}
      >
        {!loading && bannerData && bannerData.length > 0 && (
          <div className="govana-banner ">
            <Carousel
              {...rcSettings}
              showIndicators={hasMoreThanOneBanner}
              swipeable={hasMoreThanOneBanner}
              onChange={slideChange}
              className=""
              selectedItem={selectedItem}
            >
              {bannerData.map((bannerItem, i) => (
                <div id={bannerItem.id} key={bannerItem.id} className="banner-inner d-flex align-items-center ">
                  <BannerImage bannerItem={bannerItem} />
                  <div className="container-fluid  just-a-container justify-content-lg-end pr-lg-5">
                    <div className="one-rem-mb banner-content pr-lg-5">
                      {bannerItem.header && (
                        <h1 className="white-color headline-font headline  banner-small-font">
                          <DangerousHTML html={bannerItem.header} className="mb-0" />
                        </h1>
                      )}
                      {bannerItem.sub_header ? (
                        <h2 className={`bold ${appConfig.isGrassdoor ? '' : 'text-uppercase'} white-color subhead`}>
                          {bannerItem.sub_header}
                        </h2>
                      ) : null}

                      {bannerItem.paragraph && (
                        <div className="b-description white-color">
                          <DangerousHTML html={bannerItem.paragraph} className="mb-0" />
                        </div>
                      )}
                      {bannerItem.button_text && (
                        <a
                          onClick={() => redirectToLink(bannerItem, i, PRIMARY)}
                          // href={bannerItem.button_link}
                          className="click-capture-tag"
                        />
                      )}
                      {/* {onlyHomePage ? (
                        <div className="btn-group">
                          <button type className="btn btn-primary btn-order mr-lg-3" onClick={() => setTab(ASAP)}>
                            <i className="icon-stopwatch l-icon pr-2" />
                            Order Now
                          </button>
                          <button className="btn btn-primary btn-schedule mt-3 mt-lg-0" onClick={() => setTab(SCHEDULE)}>
                            <i className="icon-calendar l-icon pr-2" />
                            Schedule Later
                          </button>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    );
  }


  return (
    <div
      className={`banner lowell-banner  relative ${appConfig.isGrassdoor || hideBanner ? 'd-none  d-lg-block' : 'd-block'
        } one-rem-mb ${className}`}
    >
      {bannerData && bannerData.length > 0 && (
        <Carousel {...rcSettings} showIndicators={hasMoreThanOneBanner} swipeable={hasMoreThanOneBanner} onChange={slideChange}>
          {bannerData.map((bannerItem, i) => (
            <div key={bannerItem.id} className="banner-inner d-flex align-items-center">
              <BannerImage bannerItem={bannerItem} index={i} />
              <div className="container-fluid  just-a-container">
                <div className="one-rem-mb banner-content">
                  {bannerItem.header && (
                    <h1 className="white-color headline-font headline  banner-small-font">
                      <DangerousHTML html={bannerItem.header} className="mb-0" />
                    </h1>
                  )}
                  {bannerItem.sub_header ? (
                    <h2 className={`bold ${appConfig.isGrassdoor ? '' : 'text-uppercase'} white-color subhead`}>
                      {bannerItem.sub_header}
                    </h2>
                  ) : null}
                  {process.env.NEXT_PUBLIC_APP != 'lowell' && children}

                  {bannerItem.paragraph && (
                    <div className=" half-rem-mt  b-description white-color">
                      <DangerousHTML html={bannerItem.paragraph} className="mb-0" />
                    </div>
                  )}
                  {bannerItem.button_text && (
                    <a
                      onClick={() => redirectToLink(bannerItem, i, PRIMARY)}
                      className="btn banner-btn btn-primary btn-sm text-uppercase py-2 px-4 semi-bold mb-1 one-rem-mt "
                    >
                      {bannerItem.button_text}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}

      <div className="info-sec">
        <div className=" container text-center">
          <div className="d-flex justify-content-center align-items-center ">
            {customTextScheduleTimeSpecificText && customTextScheduleTimeSpecificText !== '' && currentTab == SCHEDULE ? (
              <>
                <i className="icon-driver-car d-none d-lg-block text-primary" />
                <div className=" ">
                  {children}
                  <span className="">{customTextScheduleTimeSpecificText}</span>
                </div>
              </>
            ) : customTextAsapTimeSpecificText && customTextAsapTimeSpecificText !== '' && currentTab == ASAP ? (
              <>
                <i className="icon-driver-car d-none d-lg-block text-primary" />
                <div className=" ">
                  {children}
                  <span className="">{customTextAsapTimeSpecificText}</span>
                </div>
              </>
            ) : customTextSchedule && customTextSchedule !== '' && currentTab == SCHEDULE ? (
              <>
                <i className="icon-driver-car d-none d-lg-block text-primary" />
                <div className=" ">
                  {children}
                  <span className="">{customTextSchedule}</span>
                </div>
              </>
            ) : customTextAsap && customTextAsap !== '' && currentTab == ASAP ? (
              <>
                <i className="icon-driver-car d-none d-lg-block text-primary" />
                <div className=" ">
                  {children}
                  <span className="">{customTextAsap}</span>
                </div>
              </>
            ) : (
              <div className=" ">{process.env.NEXT_PUBLIC_APP === 'lowell' ? children : null}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BannerImage = ({ bannerItem, index, secondaryBannerData = [] }) => {
  //let dimensionRegex = /\d+x\d+/gm;
  const getImageUrl = (type, screen) => {
    if (screen === 'desktop') {
      if (process.env.NEXT_PUBLIC_APP === WEBSITES.BENTO) {
        // remove replae for other env
        return getTransformedImageURLs(getInImgFormate(bannerItem.desktop_image_url, type)['desktop']).largeBanner;
      } else {
        return getInImgFormate(bannerItem.desktop_image_url, type)[
          process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR
            ? secondaryBannerData.length
              ? 'desktopBanner'
              : 'desktopBannerFluid'
            : 'desktop'
        ];
        // remove replae for other env
      }
    } else {
      if (process.env.NEXT_PUBLIC_APP === WEBSITES.BENTO) {
        return getTransformedImageURLs(
          getInImgFormate(bannerItem.mobile_image_url ? bannerItem.mobile_image_url : bannerItem.desktop_image_url, type).mobile
        ).srcLarge;
        // remove replae for other env
      } else {
        return getInImgFormate(bannerItem.mobile_image_url ? bannerItem.mobile_image_url : bannerItem.desktop_image_url, type)
          .mobile;
        // remove replae for other env
      }
    }
  };

  return (
    <picture className="w-100">
      <source srcSet={getImageUrl('webp', 'desktop')} type="image/webp" media="(min-width: 992px)" />
      <source srcSet={getImageUrl('jpeg', 'desktop')} type="image/jpeg" media="(min-width: 992px)" />
      <source srcSet={getImageUrl('png', 'desktop')} type="image/png" media="(min-width: 992px)" />
      <source srcSet={getImageUrl('webp', 'mobile')} type="image/webp" />
      <source srcSet={getImageUrl('jpeg', 'mobile')} type="image/jpeg" />
      <source srcSet={getImageUrl('png', 'mobile')} type="image/png" />
      <img alt={bannerItem.alt_text} src={getImageUrl('png', 'desktop')} className="banner-image" />
    </picture>
  );
};

export default Banner;
